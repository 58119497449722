import(/* webpackMode: "eager" */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/app/public/static/premium/star.svg");
import(/* webpackMode: "eager" */ "/app/public/static/svg/star.svg");
import(/* webpackMode: "eager" */ "/app/src/app/components/icons.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Layout/DarkMode/DarkModeSwitch.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Layout/Footer/atoms/CookieConsentLink.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Layout/Footer/DynamicFooterWrapper.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Layout/Header/atoms/HeaderSearch.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Layout/Header/atoms/HeaderUserMenu.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Layout/Header/DynamicHeaderWrapper.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Layout/Header/MobileHeader.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Layout/InfoBar/InfoBarClient.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Layout/LanguageSelector/LanguageSelector.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Layout/PillBar/PillBar.module.scss")