'use client';

import cn from 'classnames';
import { ReactNode } from 'react';

interface DynamicFooterWrapperProps {
  children: ReactNode;
  forceNoInfoBar?: boolean;
}
function DynamicFooterWrapper({
  children,
}: DynamicFooterWrapperProps) {

  return (
    <div
      className={cn('fixed inset-x-0 bottom-0 z-50 hidden lg:block')}
    >
      {children}
    </div>
  );
}

export default DynamicFooterWrapper;
